'use strict';

import $ from 'jquery';
import ScrollMagic from 'scrollmagic';

export default class Animations {
  constructor() {
    this.onScroll();
  }

  onScroll() {
    let controller = new ScrollMagic.Controller();
    $('.custom-anim').each(function () {
      let current = this;
      new ScrollMagic.Scene({
        triggerElement: current,
        offset: ($(this).data('offset')) ? parseInt($(this).data('offset')) : -150,
        reverse: false
      })
        .on('enter', (event) => {
          setTimeout(() => {
            $(event.target.triggerElement()).addClass('start-animation');
            if ($(event.target.triggerElement()).hasClass('video-container')) {
              let video = document.getElementById('video');
              video.play();
            }
          }, $(event.target.triggerElement()).data('delay'));

        })
        .addTo(controller);
    });

    $('.custom-anim-container').each(function () {
      let current = this;
      new ScrollMagic.Scene({
        triggerElement: current,
        offset: ($(this).data('offset')) ? parseInt($(this).data('offset')) : -$(window).height(),
        reverse: false
      })
        .on('enter', (event) => {
          $(current).find('.c-anim').each(function () {
            setTimeout(() => {
              $(this).addClass('start-animation');
              $(this).on('animationend oAnimationEnd animationend webkitAnimationEnd', function () {
                $(this).addClass('animation-end');
              });
            }, $(this).data('delay'));
          });


        })
        .addTo(controller);
    });
  }
}
