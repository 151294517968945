'use strict';

import $ from 'jquery';
export default class Sticky {
  constructor() {
    $('.sticky-button').on('click', (e) => {
      $(e.currentTarget).parent().toggleClass('active');
    });
  }
}
