'use strict';

import $ from 'jquery';

export default class Header {
  constructor() {

    setTimeout(function () {
      $('.wrap').css({'padding-top' : Math.round($('.main-header').height()) - 1});
    }, 200);


    $('.header-trigger').on('click', () => {
      $('.subnav').slideToggle(500);
      $('.subnav').toggleClass('active');
    });

    $('.close-subnav').on('click', () => {
      $('.subnav').slideToggle(500);
      $('.subnav').toggleClass('active');
    });
  }
}
