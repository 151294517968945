'use strict';

import Rellax from 'rellax';

export default class Parallax {
  constructor() {
    this.rellax = new Rellax('.parallax', {
      center: false,
      round: false,
      vertical: true,
      horizontal: false
    });

  }
}
