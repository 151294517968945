// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import imagesLoaded from 'imagesloaded';
import Parallax from './rellax';

import Animations from './animations';
import Header from '../_modules/header/header';
import Sticky from '../_modules/sticky/sticky';

$(() => {
  imagesLoaded( '.wrap', function() {
    new Header();
    new Parallax();
    new Animations();
    new Sticky();
    $('body').addClass('is-loaded');
  });

  $('article').on('click', (e) => {
    e.preventDefault();
    window.location.href = 'shop/index.html';
  });

  let video = document.getElementById('video');
  video.onloadeddata = function () {
    video.pause();
  };
});
